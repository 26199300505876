import { template as template_b205e09040204bc3a1a77abc4a36f6a7 } from "@ember/template-compiler";
const WelcomeHeader = template_b205e09040204bc3a1a77abc4a36f6a7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
