import { template as template_6d6f9538ab0d4773b7f9119aff159830 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6d6f9538ab0d4773b7f9119aff159830(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
