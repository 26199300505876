import { template as template_2f3ac671da094be08369c90da4ee11d8 } from "@ember/template-compiler";
const FKLabel = template_2f3ac671da094be08369c90da4ee11d8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
